import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
    <div>
        <section className="footer-container">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-2 col-sm-6 m-b-1 text-xs-center text-sm-left">
                        <ul className="footer-nav-wrapper">
                            <li>
                                <span className="heading-service-name">QUICK LINKS</span>
                            </li>
                            <li>
                                <Link to="/careers">Careers</Link>
                            </li>
                            <li>
                                <Link to="/about/life-at-froiden">Life At Froiden</Link>
                            </li>
                            <li>
                                <a href="http://blog.froiden.com" target="_blank" rel="noopener noreferrer">
                                    Blog
                                </a>
                            </li>
                            <li>
                                <Link to="/policy/terms-of-use">Terms of Use</Link>
                            </li>
                            <li>
                                <a href="https://shop.froiden.com" target="_blank">
                                    Shop
                                </a>
                            </li>
                        </ul>
                        <div className="clearfix" />
                    </div>

                    <div className="col-md-3 col-sm-6 m-b-1 text-xs-center text-sm-left">
                        <ul className="footer-text-wrapper">
                            <li>
                                <span className="heading-service-name">Our Products</span>
                            </li>
                            <li>
                                <a
                                    itemScope
                                    itemType="http://schema.org/Product"
                                    href="https://www.snaphrm.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span itemProp="name" className="products-heading">
                                        SnapHRM
                                    </span>
                                    <div itemProp="description" className="products-subheading">
                                        HR Management solution that delivers
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a
                                    itemScope
                                    itemType="http://schema.org/Product"
                                    href="https://worksuite.biz/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span itemProp="name" className="products-heading">
                                        Worksuite
                                    </span>
                                    <div itemProp="description" className="products-subheading">
                                        A complete Project management software
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a
                                    itemScope
                                    itemType="http://schema.org/Product"
                                    href="https://1.envato.market/recruit"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span itemProp="name" className="products-heading">
                                        Recruit
                                    </span>
                                    <div itemProp="description" className="products-subheading">
                                        Manages the recruitment process
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a
                                    itemScope
                                    itemType="http://schema.org/Product"
                                    href="https://1.envato.market/appointo"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span itemProp="name" className="products-heading">
                                        Appointo
                                    </span>
                                    <div itemProp="description" className="products-subheading">
                                        Manage the bookings/appointments
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div className="clearfix" />
                    </div>

                    <div className="col-md-4 col-sm-6 m-b-1 text-xs-center text-sm-left">
                        <ul className="footer-text-wrapper">
                            <li>
                                <div className="heading-service-name">Latest Posts</div>
                            </li>
                            <li>
                                <div className="date-container">
                                    <h6 className="date-digit">30</h6>
                                    <h6 className="date-month">MAY</h6>
                                </div>
                                <span className="count-text-wrapper">
                                    <a href="https://blog.froiden.com/project-management-features-and-functionality-203e28cfcc42">
                                        <span className="post-text">Project Management Features and Functionality</span>
                                        <span className="post-text-subheading">
                                            To manage a company efficiently you need a software for project management.
                                        </span>
                                    </a>
                                </span>
                            </li>

                            <li>
                                <div className="date-container">
                                    <h6 className="date-digit">15</h6>
                                    <h6 className="date-month">NOV</h6>
                                </div>
                                <span className="count-text-wrapper">
                                    <a href="https://blog.froiden.com/making-diwali-special-at-an-ngo-24d49cef5970">
                                        <span className="post-text">Making Diwali Special at an NGO</span>
                                        <span className="post-text-subheading">
                                            The aim of a businesses must not only be to earn profits, but also to give back to the society.
                                        </span>
                                    </a>
                                </span>
                            </li>

                            <li>
                                <div className="date-container">
                                    <h6 className="date-digit">04</h6>
                                    <h6 className="date-month">OCT</h6>
                                </div>
                                <span className="count-text-wrapper">
                                    <a href="https://blog.froiden.com/froiden-goes-to-mumbai-e649b7c926b5#.xorjytylf">
                                        <span className="post-text">Froiden goes to Mumbai</span>
                                        <span className="post-text-subheading">
                                            Have you ever wondered what is it like to be in the most star-studded capital of India? Let me
                                            pen down my experience.{' '}
                                        </span>
                                    </a>
                                </span>
                            </li>
                        </ul>
                        <div className="clearfix" />
                    </div>

                    <div className="col-md-3 col-sm-6 m-b-1 text-xs-center text-sm-left">
                        <ul className="footer-text-wrapper">
                            <li>
                                <span className="heading-service-name">CONTACT US</span>
                            </li>

                            <li>
                                <i className="fa fa-phone fa-fw contact-icon" />
                                <span className="contact-num align-num">
                                    <a itemProp="telephone" href="tel:919660788455">
                                        {' '}
                                        +91-9660788455
                                    </a>
                                </span>
                            </li>

                            <li>
                                <i className="fa fa-envelope fa-fw contact-icon" />
                                <span className="contact-num">
                                    <a itemProp="email" href="mailto:contactus@froiden.com">
                                        {' '}
                                        contactus@froiden.com
                                    </a>
                                </span>
                            </li>

                            <li>
                                <div className="social-icons">
                                    <a href="https://www.behance.net/guru6b18" target="_blank" rel="noopener noreferrer" className="tw">
                                        {' '}
                                        <i className="fa fa-behance-square" />
                                    </a>
                                    <a
                                        href="https://www.facebook.com/froiden.official"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="fb"
                                    >
                                        <i className="fa fa-facebook-square" />
                                    </a>
                                    <a href="https://dribbble.com/froiden" target="_blank" rel="noopener noreferrer" className="gp">
                                        {' '}
                                        <i className="fa fa-dribbble" />
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/froiden-technologies-private-limited"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="ln"
                                    >
                                        {' '}
                                        <i className="fa fa-linkedin-square" />
                                    </a>
                                    <a href="https://github.com/Froiden" target="_blank" rel="noopener noreferrer" className="gh">
                                        {' '}
                                        <i className="fa fa-github-square " />
                                    </a>
                                </div>
                            </li>
                        </ul>
                        <div className="clearfix" />
                    </div>
                </div>
            </div>
        </section>

        <section className="copyright-bg">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-12 pull-md-right">
                        <ul className="footer-menu-bottom">
                            <li>
                                <Link to="/policy/terms-of-use">Terms of use</Link>
                            </li>
                            <li>
                                <Link to="/policy/privacy">Privacy</Link>
                            </li>
                            <li>
                                <Link to="/policy/refund-policy">Refund policy</Link>
                            </li>
                            <li>
                                <Link to="/sitemap">Sitemap</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-sm-12 pull-md-left text-xs-center text-md-left">
                        <span className="copyright-text">©{new Date().getFullYear()} Froiden Technologies Pvt. Limited</span>
                    </div>
                </div>
            </div>
            <meta itemProp="url" content="http://www.froiden.com" />
            <meta itemProp="address" itemScope itemType="http://schema.org/PostalAddress" />
            <meta itemProp="postalCode" content="302017" />
            <meta itemProp="addressCountry" content="India" />
            <meta itemProp="addressRegion" content="Rajasthan" />
            <meta itemProp="addressLocality" content="Jaipur" />
            <meta itemProp="streetAddress" content="10/850,Malviya Nagar" />
            <meta itemProp="email" content="contactus@froiden.com" />
            <meta itemProp="telephone" content="+9196607 88455" />
            <meta itemProp="image" content="http://www.froiden.com/static/img/fbpostlogo.png" />
        </section>
    </div>
);

export default Footer;
