import React from 'react';
import { Link } from 'react-router-dom';
import View from './View';
import TestimonialComponent from './TestimonialComponent';

class Home extends React.Component {
    render() {
        return (
            <View>
                <section className="section-header">
                    <div className="overlay" />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="header-text">Web Development using Modern Technologies</h1>
                                <div className="header-subtext">
                                    <div className="text">Trusted by hundreds of clients worldwide</div>
                                </div>
                                <span className="btn-align-reset">
                                    <Link to="/why-us/technologies" className="btn btn-orange">
                                        Learn More <i className="fa fa-angle-double-right" />
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-clients-logo">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12">
                                <ul className="clients-logo">
                                    <li>
                                        <img src="/img/logo-clients/vizzot-client.svg" alt="VIZZOT" title="Vizzot.co.za" />
                                    </li>
                                    <li className="item-block">
                                        <img src="/img/logo-clients/nieuvision-client.svg" alt="NIEUVISION" title="NIEUVISION" />
                                    </li>
                                    <li className="item-block">
                                        <img src="/img/logo-clients/innovate-client.svg" alt="INNOVATE UK" title="INNOVATE UK" />
                                    </li>
                                    <li>
                                        <img src="/img/logo-clients/infinitemind-client.svg" alt="INFINITEMIND" title="INFINITEMIND" />
                                    </li>
                                    <li>
                                        <img src="/img/logo-clients/riverview-client.svg" alt="RIVERVIEW" title="RIVERVIEW" />
                                    </li>
                                    <li>
                                        <img src="/img/logo-clients/ovfl-client.svg" alt="OVFL - Ontario Varsity Football League" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-client">
                    <div className="container-fluid">
                        <div className="row content-container">
                            <div className="col-sm-12">
                                <h2 className="hairline-container m-x-auto text-xs-center">
                                    <hr className="style-line" />

                                    <span className="heading-client heading-style-shade1">Why Clients</span>
                                    <br />
                                    <span className="heading-style-shade2">choose us?</span>
                                </h2>
                            </div>
                        </div>
                        <div className="row content-container">
                            <div className="col-sm-12 col-md-6 modular-approach">
                                <h2 className="modular-approach-heading text-xs-center text-md-left">Modular Approach</h2>
                                <p className="modular-approach-content">
                                    We follow modern application architecture right from the beginning. That is why our applications are
                                    easier to maintain, easy to test and less prone to bugs, saving you both time and cost.
                                    <br />
                                    <br />
                                    <Link to="/why-us/process#modular_approach" className="more-text">
                                        Know More <i className="fa fa-angle-double-right" />
                                    </Link>
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="m-x-auto img-container">
                                    <img className="img-fluid" src="/img/Modular Apporoach.svg" alt="Modular Approach" height="320px" />
                                </div>
                            </div>
                        </div>
                        <hr className="hr-line" />
                        <div className="row content-container">
                            <div className="col-sm-12 col-md-6 modular-approach pull-md-right">
                                <h2 className="modular-approach-heading text-xs-center text-md-left">Scalable Apps</h2>
                                <p className="modular-approach-content">
                                    Our expertise in latest cloud technologies like AWS and Microsoft Azure enables your applications to
                                    scale easily. You don't have to worry about increasing traffic on your site anymore.
                                    <br />
                                    <br />
                                    <Link to="/why-us/process#scalable_apps" className="more-text">
                                        Know More <i className="fa fa-angle-double-right" />
                                    </Link>
                                </p>
                            </div>

                            <div className="col-sm-12 col-md-6 pull-md-left">
                                <div className="m-x-auto img-container">
                                    <img className="img-fluid" src="/img/sclability.svg" alt=" Scalable Apps" />
                                </div>
                            </div>
                        </div>
                        <hr className="hr-line" />

                        <div className="row content-container">
                            <div className="col-sm-12 col-md-6 modular-approach">
                                <h2 className="modular-approach-heading text-xs-center text-md-left">Test Driven Development</h2>
                                <p className="modular-approach-content">
                                    Testing is an integral part of modern applications and so is of our development process. Using latest
                                    testing tools and continuous integration, we enable you to be sure that your applications function as
                                    expected - always.
                                    <br />
                                    <br />
                                    <Link to="/why-us/process#tdd" className="more-text">
                                        Know More <i className="fa fa-angle-double-right" />
                                    </Link>
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="m-x-auto img-container">
                                    <img className="img-fluid" src="/img/testing.svg" alt="Test Driven Development" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-technologies">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 className="hairline-container hairline-tech-container m-x-auto text-xs-center">
                                    <hr className="style-line" />

                                    <span className="heading-client heading-style-shade1">Technologies</span>
                                    <br />
                                    <span className="heading-style-shade2">We Use</span>
                                </h2>
                                <img className="img-fluid img-container m-x-auto" src="/img/technologies-cloud.svg" alt="Technologies" />
                                <span className="btn-align-reset">
                                    <Link to="/why-us/technologies" className="btn btn-orange">
                                        Learn More <i className="fa fa-angle-double-right" />
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-client-testimonial">
                    <div className="container-fluid">
                        <div className="row content-container">
                            <div className="col-sm-12 col-xs-12">
                                <div className="testimonial-container">
                                    <TestimonialComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </View>
        );
    }
}

export default Home;
