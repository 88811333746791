import React from 'react';
import { Link } from 'react-router-dom';
import View from './View';

const RefundPolicy = () => (
    <View>
        <section className="policy-section-header header-transparent-bg">
            <div className="container-fluid">
                <div className="row header-container">
                    <div className="col-xs-12">
                        <h1 className="page-heading contact-heading">Refund Policy</h1>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Refund Policy</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section className="content-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <h2 className="main-heading dark-pink">Returns</h2>
                        <div className="page-content">
                            Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately we can&rsquo;t offer you a
                            refund or exchange.
                        </div>
                        <h2 className="main-heading dark-pink">Non-returnable items:</h2>
                        <div className="page-content">
                            <ul className="unordered-list">
                                <li>Downloadable software products</li>
                                <li>To complete your return, we require a receipt or proof of purchase.</li>
                            </ul>
                        </div>
                        <h2 className="main-heading dark-pink">Refunds (if applicable)</h2>
                        <div className="page-content">
                            <p>
                                Once your return is received and inspected, we will send you an email to notify you that we have received
                                your returned item. We will also notify you of the approval or rejection of your refund. If you are
                                approved, then your refund will be processed, and a credit will automatically be applied to your credit card
                                or original method of payment, within a certain amount of days.
                            </p>
                        </div>
                        <h2 className="main-heading dark-pink">Late or missing refunds (if applicable)</h2>
                        <div className="page-content">
                            <ul className="unordered-list">
                                <li>If you haven&rsquo;t received a refund yet, first check your bank account again.</li>
                                <li>
                                    Then contact your credit card company, it may take some time before your refund is officially posted.
                                </li>
                                <li>Next contact your bank. There is often some processing time before a refund is posted.</li>
                                <li>
                                    If you&rsquo;ve done all of this and you still have not received your refund yet, please contact us at
                                    contactus@froiden.com.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </View>
);

export default RefundPolicy;
