import React from 'react';
import { Link } from 'react-router-dom';
// import ContactFormContainer from './ContactFormContainer';

import GoogleMapsLoader from 'google-maps';

class ContactUs extends React.Component {
    componentDidMount() {
        GoogleMapsLoader.KEY = 'AIzaSyD_uuUuaf0QfvJgHJ6emLwdR9w2lXHzjZk';

        GoogleMapsLoader.load(function (google) {
            var var_location = new google.maps.LatLng(26.8529663, 75.8053399);
            var var_mapoptions = {
                center: var_location,
                zoom: 16,
                scrollwheel: false,
            };

            var map = new google.maps.Map(document.getElementById('map-canvas'), var_mapoptions);
            // add marker
            var iconBase = {
                url: '/img/marker-01.svg', // url
                scaledSize: new google.maps.Size(33, 50), // scaled size
            };
            var marker = new google.maps.Marker({
                position: { lat: 26.8529663, lng: 75.8053399 },
                map: map,
                icon: iconBase,
            });
            // preparing infowindow
            var infowindow = new google.maps.InfoWindow({
                content: `<h2 class="title-map">Froiden Technologies Pvt. Ltd.</h2><div id="info"><h4>3rd Floor, Plot no. B-06, Mahalaxmi Nagar Rd, D-Block, Malviya Nagar,<br> Rajasthan, India - 302017</h4></div>`,
            });
            // adding listener, so infowindow is opened
            marker.addListener('click', function (event) {
                infowindow.open(map, marker);
            });
            infowindow.open(map, marker);
        });
    }

    render() {
        return (
            <div className="contactus" id="contactus">
                <section className="contact-section-header header-transparent-bg">
                    <div className="container-fluid">
                        <div className="row header-container">
                            <div className="col-xs-12">
                                <h1 className="page-heading contact-heading">Contact Us</h1>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Contact Us</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="form-section">
                    <div itemType="http://schema.org/LocalBusiness" className="container-fluid">
                        <div className="row">
                            <div className="col-sm-4  contact-padding">
                                <div className="circle m-x-auto">
                                    <span>
                                        <i className="fa fa-map-marker fa-fw"></i>
                                    </span>
                                </div>
                                <div className="heading-contact text-md-center text-xs-center text-sm-center">Address</div>
                                <div
                                    itemProp="address"
                                    itemScope
                                    itemType="http://schema.org/PostalAddress"
                                    className="contact-description text-md-center text-xs-center text-sm-center"
                                >
                                    <span itemProp="streetAddress">
                                        3rd Floor,Plot B-06,
                                        <br /> Mahalaxmi Nagar, Malviya Nagar
                                    </span>
                                    <br />
                                    <span itemProp="addressLocality">Jaipur,</span> <span itemProp="addressRegion">Rajasthan</span>
                                    <br />
                                    <span itemProp="addressCountry">India</span> - <span itemProp="postalCode">302017</span>
                                </div>
                            </div>

                            <div className="col-sm-4 contact-padding">
                                <div className="circle m-x-auto">
                                    <span>
                                        <i className="fa fa-phone fa-fw"></i>
                                    </span>
                                </div>
                                <div className="heading-contact text-md-center text-xs-center text-sm-center">Phone Number</div>
                                <div className="phone-num text-md-center text-xs-center text-sm-center">
                                    <span itemProp="telephone"> +91-9660788455</span>
                                    <br />
                                </div>
                            </div>

                            <div className="col-sm-4 contact-padding">
                                <div className="circle m-x-auto">
                                    <span>
                                        <i className="fa fa-envelope fa-fw"></i>
                                    </span>
                                </div>
                                <div className="heading-contact text-md-center text-xs-center text-sm-center">Email</div>
                                <div itemProp="email" className="contact-description text-md-center text-xs-center text-sm-center">
                                    contactus@froiden.com
                                </div>
                                <div itemProp="email" className="contact-description text-md-center text-xs-center text-sm-center">
                                    enquiry@froiden.com
                                </div>
                                <div itemProp="email" className="contact-description text-md-center text-xs-center text-sm-center">
                                    career@froiden.com
                                </div>
                            </div>
                        </div>
                        <hr className="" />
                        <div id="map-canvas" className="map-reset z-depth-1"></div>
                        <hr className="" />

                        {/*<ContactFormContainer />*/}
                    </div>
                </section>
            </div>
        );
    }
}

export default ContactUs;
