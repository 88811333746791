import React from 'react';
import { Link } from 'react-router-dom';
import View from '../View';
import JobsList from '../../components/Career/JobList';

class Career extends React.Component {
    render() {
        return (
            <View>
                <section className="career-section-header header-transparent-bg">
                    <div className="container-fluid">
                        <div className="row header-container">
                            <div className="col-xs-12">
                                <h1 className="page-heading contact-heading">Careers</h1>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Careers</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="intro-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 className="intro-heading text-xs-center">Are you passionate to Learn and Grow? Join us!</h2>
                                <div className="content text-xs-center">
                                    Working at Froiden means Learning everyday. We do what we love and love what we do. We are young, but we
                                    are growing. Do you want to be a part of this journey? Come, join us!
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="about-froiden">
                    <div className="container-fluid">
                        <div className="row ">
                            <div className="col-sm-12">
                                <h2 className="intro-heading text-xs-center">What you will like about Froiden</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 m-t-30">
                                <div className="circle m-x-auto">
                                    <div className="circle circle-border m-x-auto">
                                        <div className="lnr lnr-thumbs-up text-xs-center icon-style" />
                                    </div>
                                </div>
                                <div className="about-text text-xs-center">Love what you do</div>
                            </div>
                            <div className="col-sm-4 m-t-30">
                                <div className="circle m-x-auto">
                                    <div className="circle circle-border m-x-auto">
                                        <div className="lnr lnr-eye text-xs-center icon-style" />
                                    </div>
                                </div>
                                <div className="about-text text-xs-center">Encouraging new Ideas</div>
                            </div>
                            <div className="col-sm-4 m-t-30">
                                <div className="circle m-x-auto">
                                    <div className="circle circle-border m-x-auto">
                                        <div className="lnr lnr-rocket text-xs-center icon-style" />
                                    </div>
                                </div>
                                <div className="about-text text-xs-center">Focus on Individual Growth</div>
                            </div>

                            <div className="col-sm-4 m-t-30">
                                <div className="circle m-x-auto">
                                    <div className="circle circle-border m-x-auto">
                                        <div className="lnr lnr-users text-xs-center icon-style" />
                                    </div>
                                </div>
                                <div className="about-text text-xs-center">Stay as a Family</div>
                            </div>
                            <div className="col-sm-4 m-t-30">
                                <div className="circle m-x-auto">
                                    <div className="circle circle-border m-x-auto">
                                        <div className="lnr lnr-smile text-xs-center icon-style" />
                                    </div>
                                </div>
                                <div className="about-text text-xs-center">Everyone is happy here</div>
                            </div>
                            <div className="col-sm-4 m-t-30">
                                <div className="circle m-x-auto">
                                    <div className="circle circle-border m-x-auto text-sm-center">
                                        <div className="lnr lnr-coffee-cup text-xs-center icon-style" />
                                    </div>
                                </div>
                                <div className="about-text text-xs-center ">The Parties!</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="intro-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 className="intro-heading text-xs-center">A Little Background...</h2>
                                <div className="content background-froiden">
                                    <p>
                                        Froiden is an outsourcing services provider for small and medium businesses, based in{' '}
                                        <a href="https://en.wikipedia.org/wiki/Jaipur" target="_blank" rel="noopener noreferrer">
                                            Jaipur, Rajasthan
                                        </a>
                                        . It is a young company, started somewhere in 2013 and incorporated in 2014.
                                    </p>

                                    <p>
                                        We exclusively focus on providing services related to web applications. We do have plans for
                                        expanding to other emerging domains in near future.
                                    </p>

                                    <p>
                                        We are not limited to providing outsourcing services. We have equal focus on developing our own
                                        products. While our products also are web applications and supporting mobile applications, we do not
                                        focus on any particular subject or industry. Our products tend to provide solutions to common
                                        problems faced by people. We do not develop products if similar ones are already in the market,
                                        unless the existing products can be greatly improved upon.
                                    </p>

                                    <p>
                                        While working with Froiden, one thing that you will experience the most is the difference from
                                        others in the way we work and see things, and why is this difference so important.
                                    </p>

                                    <p>
                                        Even smallest of things done differently can result in exceptional outcomes. This is what drives us
                                        to do things the way we think is right, and not just going by the book. This leads to innovations
                                        you find at Froiden.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="life-foiden-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 text-xs-center">
                                <span className="heading-text">Life At Froiden</span>
                                <Link to="/about/life-at-froiden" className="btn btn-light-blue life-at-froiden-link">
                                    See Now
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="vacancy-section">
                    <JobsList />
                </section>
            </View>
        );
    }
}

export default Career;
