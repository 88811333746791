import React from 'react';
import { NavLink } from 'react-router-dom';
import Slideout from 'slideout';

const $ = window.$;

class Header extends React.Component {
    componentDidMount() {
        var toggles = document.querySelectorAll('.c-hamburger');

        for (var i = toggles.length - 1; i >= 0; i--) {
            var toggle = toggles[i];
            toggleHandler(toggle);
        }

        function toggleHandler(toggle) {
            toggle.addEventListener('click', function (e) {
                e.preventDefault();
                this.classList.contains('is-active') === true ? this.classList.remove('is-active') : this.classList.add('is-active');
            });
        }

        var slideout = new Slideout({
            panel: document.getElementById('panel'),
            menu: document.getElementById('menu'),
            padding: 256,
            tolerance: 70,
            touch: false,
        });

        window.slideout = slideout;

        document.querySelector('.js-slideout-toggle').addEventListener('click', function () {
            slideout.toggle();
        });

        // Hide menu when clicked outside
        $(document, '.text-format-slideout').click(function (event) {
            if (!$(event.target).closest('#menu').length && !$(event.target).closest('.c-hamburger').length) {
                slideout.close();
                $('.c-hamburger').removeClass('is-active');
            }
        });

        $('.text-format-slideout').click(function (event) {
            $('.c-hamburger').removeClass('is-active');
            slideout.close();
        });

        $('#menu').removeClass('hide');

        //Slide Out Toggle
        $('.ids').click(function () {
            var point = $(this);

            if (point.attr('class') === 'ids text-format-slideout menu-item-has-children') {
                point.removeClass('menu-item-has-children').addClass('add-after');
            } else {
                point.removeClass('add-after').addClass('menu-item-has-children');
            }

            $(this).parent('li').children('div').slideToggle('slow');
        });
    }

    render() {
        return (
            <div>
                <div>
                    <nav id="menu" className="slideout-menu">
                        <header>
                            <h3 className="heading-menu-mobile">MENU</h3>
                            <ul className="mobile-nav">
                                <li>
                                    <NavLink to="/" exact className="text-format-slideout">
                                        <span className="lnr lnr-home"></span>Home
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/products" exact className="text-format-slideout">
                                        <span className="lnr lnr-dice"></span>Products
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/services" className="text-format-slideout">
                                        <span className="lnr lnr-cog" />
                                        Services
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink to="/careers" className="text-format-slideout">
                                        <span className="lnr lnr-graduation-hat" />
                                        Careers
                                    </NavLink>
                                </li>

                                <li>
                                    <a href="https://shop.froiden.com" target="_blank" className="nav-link" activeclassname="active">
                                        <span className="lnr lnr-cart" /> Shop
                                    </a>
                                </li>

                                <li className="">
                                    <a href="#" className="link-button ids menu-item-has-children">
                                        <span className="lnr lnr-users" />
                                        About Us
                                    </a>
                                    <div className="dropdown">
                                        <div className="dd-inner">
                                            <ul className="column">
                                                <li>
                                                    <NavLink to="/about/overview" className="text-format-slideout">
                                                        Overview
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/about/history" className="text-format-slideout">
                                                        History
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/about/team" className="text-format-slideout">
                                                        Our Team
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/about/life-at-froiden" className="text-format-slideout">
                                                        Life at froiden
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <a href="#" className="ids menu-item-has-children">
                                        <span className="lnr lnr-thumbs-up" />
                                        Why Us?
                                    </a>
                                    <div className="dropdown">
                                        <div className="dd-inner">
                                            <ul className="column">
                                                <li>
                                                    <NavLink to="/why-us/technologies" className="text-format-slideout">
                                                        Technologies
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/why-us/process" className="text-format-slideout">
                                                        Process
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/why-us/coding-standards" className="text-format-slideout">
                                                        Coding Standard
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <NavLink to="/contact-us" className="text-format-slideout">
                                        <span className="lnr lnr-phone"></span>Contact Us
                                    </NavLink>
                                </li>
                            </ul>
                        </header>
                    </nav>
                </div>
                <div id="panel">
                    <section className="menu-bg">
                        <div className="header-strip">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6 col-md-12 col-lg-4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 text-xs-center text-md-center text-lg-left">
                                                <div className="js-slideout-toggle pull-md-left">
                                                    <div className="o-grid__item">
                                                        <button className="c-hamburger c-hamburger--htx">
                                                            <span>toggle menu</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <NavLink to="/">
                                                    <img className="img-logo text-xs-center text-sm-center" src="/img/white-logo.svg" />{' '}
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <nav className="navbar hidden-md-down">
                                            <ul className="nav navbar-nav">
                                                <li className="nav-item">
                                                    <NavLink className="nav-link nav-reset" exact to="/">
                                                        Home
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link nav-reset" exact to="/products">
                                                        Products
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" activeclassname="active" to="/services">
                                                        Services
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        href="javascript:;"
                                                        className="dropdown-toggle nav-link"
                                                        data-toggle="dropdown"
                                                        role="button"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        About us <span className="caret" />
                                                    </a>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <NavLink to="/about/overview">Overview</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/about/history">History</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/about/team">Our Team</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/about/life-at-froiden">Life at froiden</NavLink>
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li className="nav-item">
                                                    <a
                                                        href="#"
                                                        className="dropdown-toggle nav-link"
                                                        data-toggle="dropdown"
                                                        role="button"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        Why us? <span className="caret"></span>
                                                    </a>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <NavLink to="/why-us/technologies">Technologies</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/why-us/process">Process</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to="/why-us/coding-standards">Coding Standard</NavLink>
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li className="nav-item">
                                                    <NavLink className="nav-link" activeclassname="active" to="/careers">
                                                        Careers
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        href="https://shop.froiden.com"
                                                        target="_blank"
                                                        className="nav-link"
                                                        activeclassname="active"
                                                    >
                                                        Shop
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink className="nav-link" activeclassname="active" to="/contact-us">
                                                        Contact us
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default Header;
