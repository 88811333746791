import React from 'react';
import { Link } from 'react-router-dom';
import View from './View';
const $ = window.$;
class Technology extends React.Component {
    componentDidMount() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    render() {
        return (
            <View>
                <section className="technology-section header-transparent-bg">
                    <div className="container-fluid">
                        <div className="row header-container">
                            <div className="col-xs-12">
                                <h1 className="page-heading contact-heading">Technologies</h1>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Technologies</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 className="main-heading dark-pink">Our Technology Stack</h2>
                                <div className="page-content">
                                    <p>
                                        Modern web development is characterised by ever-changing technology space. With the evolution of
                                        open source software, new technologies arrive every other day. In order to keep up with the latest
                                        trends so that our applications are not based on outdated technologies, we quickly adopt new tools
                                        into our process. The table below lists the technologies we are using in our process currently.
                                    </p>
                                    <p>&nbsp;</p>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <td>Class</td>
                                                    <td>Technologies</td>
                                                    <td>Comments</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>Backend</strong>
                                                    </td>
                                                    <td>
                                                        <a href="https://laravel.com" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/laravel.svg"
                                                                className="technology-icon"
                                                                style={{ height: '3.125rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Laravel"
                                                                alt="Laravel"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <ul className="unordered-list">
                                                            <li>We exclusively work with Laravel PHP framework.</li>
                                                            <li>
                                                                For APIs, we can also work with{' '}
                                                                <a
                                                                    href="https://lumen.laravel.com/"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    Lumen Framework
                                                                </a>
                                                                .
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Frontend</strong>
                                                    </td>
                                                    <td>
                                                        <a href="https://vuejs.org" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/vuejs.svg"
                                                                className="technology-icon"
                                                                style={{ height: '2.813rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Vue.js"
                                                                alt="Vue.js"
                                                            />
                                                        </a>
                                                        <a href="https://angular.io" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/angular-icon.svg"
                                                                className="technology-icon"
                                                                style={{ height: '3.125rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Angular"
                                                                alt="Angular js"
                                                            />
                                                        </a>
                                                        <a href="https://www.typescriptlang.org/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/typescript-logo-01.svg"
                                                                className="technology-icon"
                                                                style={{ height: '4.063rem', marginTop: '-0.625rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="TypeScript"
                                                                alt="TypeScript"
                                                            />
                                                        </a>
                                                        <a href="https://babeljs.io/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/babel.svg"
                                                                className="technology-icon"
                                                                style={{ height: '3.125rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Babel"
                                                                alt="Babel"
                                                            />
                                                        </a>

                                                        <a href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/react.svg"
                                                                className="technology-icon"
                                                                style={{ height: '3.125rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="ReactJs"
                                                                alt="ReactJs"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <ul className="unordered-list">
                                                            <li>
                                                                We prefer to work with Vue.js. We follow single file component pattern and
                                                                write JavaScript code in Babel.
                                                            </li>
                                                            <li>For Angular, we write code in TypeScript</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Task Runners</strong>
                                                    </td>
                                                    <td>
                                                        <a href="http://gulpjs.com/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/gulp.svg"
                                                                className="technology-icon"
                                                                style={{ height: '4.375rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Gulp"
                                                                alt="Gulp"
                                                            />
                                                        </a>
                                                        <a href="http://gruntjs.com/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/grunt.svg"
                                                                className="technology-icon"
                                                                style={{ height: '4.375rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Grunt"
                                                                alt="Grunt"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <ul className="unordered-list">
                                                            <li>Gulp is our preferred task runner.</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Version Control</strong>
                                                    </td>
                                                    <td>
                                                        <a href="https://git-scm.com/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/git-icon.svg"
                                                                className="technology-icon"
                                                                style={{ height: '3.125rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Git"
                                                                alt="Git"
                                                            />
                                                        </a>
                                                        <a href="http://gitlab.com/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/gitlab.svg"
                                                                className="technology-icon"
                                                                style={{ height: '3.125rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Gitlab"
                                                                alt="Gitlab"
                                                            />
                                                        </a>
                                                        <a href="http://bitbucket.com/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/bitbucket.svg"
                                                                className="technology-icon"
                                                                style={{ height: '3.125rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Bitbucket"
                                                                alt="Bitbucket"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <ul className="unordered-list">
                                                            <li>We exclusively work with Git</li>
                                                            <li>We have a local Gitlab installation to manage all our Git repositories.</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Database</strong>
                                                    </td>
                                                    <td>
                                                        <a href="https://mysql.com/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/mysql.svg"
                                                                className="technology-icon"
                                                                style={{ height: '4.375rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="MySql"
                                                                alt="MySql"
                                                            />
                                                        </a>
                                                        <a href="http://mongodb.com/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/mongodb-01.svg"
                                                                className="technology-icon"
                                                                style={{ height: '4.375rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="mongoDB"
                                                                alt="mongoDB"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <ul className="unordered-list">
                                                            <li>
                                                                Our 90% work is in MySQL. We occasionally work with MongoDB, if client
                                                                requires.
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Error Logging</strong>
                                                    </td>
                                                    <td>
                                                        <a href="https://sentry.io" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/sentry.svg"
                                                                className="technology-icon"
                                                                style={{ height: '3.125rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Sentry"
                                                                alt="Sentry"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <ul className="unordered-list">
                                                            <li>
                                                                We have local Sentry installation to which all errors in all installations
                                                                of your application are logged.
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Package Managers</strong>
                                                    </td>
                                                    <td>
                                                        <a href="https://getcomposer.org/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/composer.svg"
                                                                className="technology-icon"
                                                                style={{ height: '4.375rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Composer"
                                                                alt="Composer"
                                                            />
                                                        </a>
                                                        <a href="https://npmjs.com/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/npm.svg"
                                                                className="technology-icon"
                                                                style={{ height: '2.5rem', marginTop: '0.9375rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="npm"
                                                                alt="Npm"
                                                            />
                                                        </a>
                                                        <a href="https://bower.io/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/bower.svg"
                                                                className="technology-icon"
                                                                style={{ height: '4.063rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Bower"
                                                                alt="Bower"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <ul className="unordered-list">
                                                            <li>
                                                                We have some of our own packages on Composer and Npm repositories{' '}
                                                                <img
                                                                    src="https://cdnjs.cloudflare.com/ajax/libs/emojione/2.2.7/assets/png/1f642.png"
                                                                    alt="We have some of our own packages on Composer and Npm
                                                            repositories"
                                                                    style={{ height: '1rem' }}
                                                                />
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Communication</strong>
                                                    </td>
                                                    <td>
                                                        <a href="https://slack.com" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/slack.svg"
                                                                className="technology-icon"
                                                                style={{
                                                                    height: '5.625rem',
                                                                    marginTop: '-1.438rem',
                                                                    marginLeft: '-0.3125rem',
                                                                }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Slack"
                                                                alt="Slack"
                                                            />
                                                        </a>
                                                        <a href="https://skype.com" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/skype-3.svg"
                                                                className="technology-icon"
                                                                style={{ height: '2.5rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Skype"
                                                                alt="Skype"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <ul className="unordered-list">
                                                            <li>
                                                                We love Slack{' '}
                                                                <img
                                                                    src="https://cdnjs.cloudflare.com/ajax/libs/emojione/2.2.7/assets/png/1f642.png"
                                                                    style={{ height: '1rem' }}
                                                                    alt="We Love Slack"
                                                                />
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Testing</strong>
                                                    </td>
                                                    <td>
                                                        <a href="https://phpunit.de" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/phpunit.svg"
                                                                className="technology-icon"
                                                                style={{ height: '1.25rem', marginTop: '1rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="PHPUnit"
                                                                alt="PHPUnit"
                                                            />
                                                        </a>
                                                        <a href="https://jasmine.github.io/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/jasmine.svg"
                                                                className="technology-icon"
                                                                style={{ height: '3.125rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Jasmine"
                                                                alt="Jasmine"
                                                            />
                                                        </a>
                                                        <a href="http://codeception.com/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/codeception.svg"
                                                                className="technology-icon"
                                                                style={{ height: '3.125rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Codeception"
                                                                alt="Codeception"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <ul className="unordered-list">
                                                            <li>For integration tests, we use Codeception with Phantom.js.</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Continuous Integration</strong>
                                                    </td>
                                                    <td>
                                                        <a
                                                            href="https://about.gitlab.com/gitlab-ci/"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img
                                                                src="/img/technology/gitlab.svg"
                                                                className="technology-icon"
                                                                style={{ height: '3.125rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Gitlab CI"
                                                                alt="Gitlab CI"
                                                            />
                                                        </a>
                                                        <a href="https://jenkins.io/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/jenkins.svg"
                                                                className="technology-icon"
                                                                style={{ height: '3.125rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Jenkins"
                                                                alt="Jenkins"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <ul className="unordered-list">
                                                            <li>We prefer to use Gitlab CI</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Cloud</strong>
                                                    </td>
                                                    <td>
                                                        <a href="https://aws.amazon.com/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/amazon-web-services-01.svg"
                                                                className="technology-icon"
                                                                style={{ height: '4.375rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Amazon AWS"
                                                                alt="Amazon AWS"
                                                            />
                                                        </a>
                                                        <a
                                                            href="https://azure.microsoft.com/en-in/"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img
                                                                src="/img/technology/azure.svg"
                                                                className="technology-icon"
                                                                data-toggle="tooltip"
                                                                style={{ height: '1.25rem', marginTop: '1.25rem' }}
                                                                data-placement="bottom"
                                                                title="Microsoft Azure"
                                                                alt="Microsoft Azure"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <ul className="unordered-list">
                                                            <li>
                                                                Amazon AWS is our first choice. However, we are also comfortable working
                                                                with Azure.
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>CSS Extensions</strong>
                                                    </td>
                                                    <td>
                                                        <a href="http://sass-lang.com/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/sass-1.svg"
                                                                className="technology-icon"
                                                                style={{ height: '2.5rem' }}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="SASS"
                                                                alt="SASS"
                                                            />
                                                        </a>
                                                        <a href="http://lesscss.org/" target="_blank" rel="noopener noreferrer">
                                                            <img
                                                                src="/img/technology/less.svg"
                                                                className="technology-icon"
                                                                data-toggle="tooltip"
                                                                style={{ height: '2.5rem' }}
                                                                data-placement="bottom"
                                                                title="LESS"
                                                                alt="LESS"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <ul className="unordered-list">
                                                            <li>We prefer to work on SASS</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </View>
        );
    }
}

export default Technology;
