import React from 'react';
import Busy from '../includes/Busy.js';
import { Link } from 'react-router-dom';
import { API_EMPLOYEES } from '../Config.js';
import View from './View';

class Team extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            employees: [],
            busy: true,
        };
    }

    componentDidMount() {
        // Attempt to fetch data from cache
        const cachedData = localStorage.getItem('froiden-employees-data');
        const cachedTimestamp = localStorage.getItem('froiden-employees-data-timestamp');

        if (cachedData && cachedTimestamp) {
            const parsedData = JSON.parse(cachedData);
            const cachedTime = new Date(cachedTimestamp).getTime();
            const currentTime = new Date().getTime();

            // Check if the cached data is less than 24 hours old
            if (currentTime - cachedTime < 24 * 60 * 60 * 1000) {
                this.setState({ employees: parsedData, busy: false });
                return; // Use cached data if it's still valid
            }
        }

        // If no valid cached data or if it's stale, fetch it from the API
        fetch(API_EMPLOYEES)
            .then((response) => response.json())
            .then((data) => {
                this.setState({ employees: data.data, busy: false });

                // Cache the fetched data with a timestamp
                localStorage.setItem('froiden-employees-data', JSON.stringify(data.data));
                localStorage.setItem('froiden-employees-data-timestamp', new Date().toISOString());
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                this.setState({ busy: false });
            });
    }

    render() {
        const data = this.state.employees;

        const Social = (props) => {
            if (props.field) {
                return (
                    <a href={props.link} target="_blank" rel="noopener noreferrer" className="tw">
                        <i className={props.icon} />
                    </a>
                );
            }
            return null;
        };

        const Employee = (props) => (
            <div className="col-sm-6 col-md-3 col-lg-3 row-container">
                <div className="m-x-auto image-property">
                    <img width="150px" height="150px" src={props.user.profile_image_url} className="img-circle" alt={props.user.name} />
                </div>

                <div className="text-sm-center text-xs-center emp-name">{props.user.name}</div>

                <div className="designation text-sm-center text-xs-center">{props.user.designation ? props.user.designation.name : ''}</div>
                {/*  */}
            </div>
        );

        const EmployeeList = () =>
            Object.keys(data).map(function (key) {
                return <Employee key={key} user={data[key]} />;
            });

        return (
            <View>
                <section className="team-section-header header-transparent-bg">
                    <div className="container-fluid">
                        <div className="row header-container">
                            <div className="col-xs-12">
                                <h1 className="page-heading team-heading">Our Team</h1>
                                <div className="team-subheading">"A team of millenials bringing innovation to the masses"</div>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/about/overview">About</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Team</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-team">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="about-team m-x-auto">~ Small Team - Big Impact ~</div>
                            </div>
                            <div className="row">
                                <Busy busy={this.state.busy} />

                                <EmployeeList />
                            </div>
                        </div>
                    </div>
                </section>
            </View>
        );
    }
}

export default Team;
