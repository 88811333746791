import React from 'react';
import { Link } from 'react-router-dom';
import Busy from '../../includes/Busy.js';
import { API_JOBS } from '../../Config';
import CareerFormContainer from './CareerFormContainer';
import View from '../View';

const $ = window.$;

class CareerApplyNow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            job: [],
            busy: true,
            jobID: props.match.params.id,
        };
    }

    componentDidMount() {
        const jobID = this.props.match.params.id;
        fetch(API_JOBS + '/' + jobID)
            .then((response) => response.json())
            .then((data) => {
                this.setState({ job: data.data, busy: false });
                $('#career-form-open-bottom,#career-form-open').slideNavRight();
                $('.close,.slide-overlay').closeSlideNav();
            });
    }

    render() {
        const Description = (props) => {
            if (props.headingObject !== '') {
                return (
                    <div>
                        <h2 className={'description-info-heading ' + (props.heading !== 'Introduction' ? 'heading-align-reset' : '')}>
                            {props.heading}
                        </h2>
                        <div className="info-content" dangerouslySetInnerHTML={{ __html: props.headingObject }} />
                    </div>
                );
            }
        };

        return (
            <View>
                <section className="career-section-header header-transparent-bg">
                    <div className="container-fluid">
                        <div className="row header-container">
                            <div className="col-xs-12">
                                <h1 className="page-heading contact-heading">{this.state.job.role}</h1>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Careers</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                {!this.state.busy && this.state.job.status === 'closed' && (
                    <section className="description-info-container" style={{ margin: '300px 0 300px 0' }}>
                        <div className="container-fluid">
                            <h2 className="intro-heading text-xs-center p-100">
                                Sorry, the job is closed now{' '}
                                <img
                                    src="https://cdnjs.cloudflare.com/ajax/libs/emojione/2.2.7/assets/png/1f625.png"
                                    alt="Sad Icon"
                                    style={{ height: '2rem' }}
                                />
                            </h2>
                        </div>
                    </section>
                )}

                {!this.state.busy && this.state.job.status === 'open' && (
                    <>
                        <section className="info-designation">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12">
                                        {!this.state.busy && (
                                            <ul className="job-description">
                                                <li>
                                                    <p>
                                                        <span className="description-heading">CTC</span>
                                                        <br />
                                                        <span className="description">
                                                            {this.state.job.ctc ? this.state.job.ctc : ' NA '}
                                                        </span>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="description-heading">Role</span>
                                                        <br />
                                                        <span className="description">{this.state.job.role}</span>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="description-heading">Location</span>
                                                        <br />
                                                        <span className="description">Jaipur</span>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <span className="description-heading">Experience</span>
                                                        <br />
                                                        <span className="description">{this.state.job.experience_required}</span>
                                                    </p>
                                                </li>
                                            </ul>
                                        )}
                                        <div className="btn-reset text-xs-center">
                                            <button className="btn btn-success" disabled={this.state.busy} id="career-form-open-bottom">
                                                Apply Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="description-info-container">
                            <div className="container-fluid">
                                <div className="row">
                                    <Busy busy={this.state.busy} />
                                    {!this.state.busy && (
                                        <div className="col-sm-12">
                                            <Description heading="Introduction" headingObject={this.state.job.introduction} />
                                            <Description heading="Responsibilities" headingObject={this.state.job.responsibilities} />
                                            <Description heading="Skill Set" headingObject={this.state.job.skill_set} />
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="btn-reset career-apply-bottom-btn text-xs-center text-sm-left">
                                            <button
                                                id="career-form-open"
                                                type="submit"
                                                disabled={this.state.busy}
                                                className="btn btn-success"
                                            >
                                                Apply Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )}
                <div className="slide-overlay" />
                <div id="career-form" className="slide-form">
                    <CareerFormContainer jobID={this.state.jobID} />
                </div>
            </View>
        );
    }
}

export default CareerApplyNow;
