import React from 'react';
import { Link } from 'react-router-dom';
import View from './View';

const Products = () => (
    <View>
        <section className="service-section-header product-section-header header-transparent-bg">
            <div className="container-fluid">
                <div className="row header-container">
                    <div className="col-xs-12">
                        <h1 className="page-heading service-heading">Products</h1>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Products</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section className="service-section web-application-bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <div className="card card-product text-center">
                            <span className="product-category">HR Management Software</span>
                            <br />
                            <a href="https://snaphrm.com/" className="product-logo" target="_blank" rel="noopener noreferrer">
                                <img src="/img/products/snaphrm-orange.svg" alt="snapHRM" />
                            </a>
                            <p>HR Management Solution for Small and Medium Businesses</p>
                            <a href="https://snaphrm.com/" className="product-learn-more" target="_blank" rel="noopener noreferrer">
                                Learn more <span className="lnr lnr-arrow-right"></span>
                            </a>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4">
                        <div className="card card-product text-center">
                            <span className="product-category">Project Management Software</span>
                            <a href="https://worksuite.biz/" className="product-logo" target="_blank" rel="noopener noreferrer">
                                <img src="/img/products/worksuite-logo-full.svg" alt="Worksuite" />
                            </a>
                            <p>
                                Plan, track and keep work
                                <br />
                                organised.
                            </p>
                            <a href="https://worksuite.biz/" className="product-learn-more" target="_blank" rel="noopener noreferrer">
                                Learn more <span className="lnr lnr-arrow-right"></span>
                            </a>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4">
                        <div className="card card-product text-center">
                            <span className="product-category">Recruitment Management Software</span>
                            <a href="https://1.envato.market/recruit" className="product-logo" target="_blank" rel="noopener noreferrer">
                                <img src="/img/products/recruit-logo-dark.svg" alt="Recruit" />
                            </a>
                            <p>Attract applications, engage with candidates and hire superstars.</p>
                            <a
                                href="https://1.envato.market/recruit"
                                className="product-learn-more"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Learn more <span className="lnr lnr-arrow-right"></span>
                            </a>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4">
                        <div className="card card-product text-center">
                            <span className="product-category">Appointment Management Software</span>
                            <a href="https://1.envato.market/appointo" className="product-logo" target="_blank" rel="noopener noreferrer">
                                <img src="/img/products/appointo-logo-dark.svg" alt="Appointo" />
                            </a>
                            <p>Booking/Appointment platform and manage customers.</p>
                            <a
                                href="https://1.envato.market/appointo"
                                className="product-learn-more"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Learn more <span className="lnr lnr-arrow-right"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="service-section trusted-section designing">
            <div className="container-fluid">
                <div className="row content-container">
                    <div className="col-sm-12">
                        <h2 className="trusted-heading text-center">Trusted by thousands of businesses worldwide.</h2>
                    </div>
                </div>
            </div>
        </section>
    </View>
);

export default Products;
