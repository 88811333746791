import React from 'react';
import { Link } from 'react-router-dom';
import View from './View';

const Sitemap = () => (
    <View>
        <section className="sitemap-section-header header-transparent-bg">
            <div className="container-fluid">
                <div className="row header-container">
                    <div className="col-xs-12">
                        <h1 className="page-heading team-heading">Sitemap</h1>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Sitemap</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-sitemap">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4">
                        <h3>
                            <Link to="/">Home</Link>
                        </h3>
                    </div>
                    <div className="col-sm-4">
                        <h3>
                            <Link to="/products">Products</Link>
                        </h3>
                    </div>
                    <div className="col-sm-4">
                        <h3>
                            <Link to="/services">Services</Link>
                        </h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <h3>
                            <button className="link-button">About Us</button>
                        </h3>
                        <ul>
                            <li>
                                <Link to="/about/overview">Overview</Link>
                            </li>
                            <li>
                                <Link to="/about/history">History</Link>
                            </li>
                            <li>
                                <Link to="/about/team">Our Team</Link>
                            </li>
                            <li>
                                <Link to="/about/life-at-froiden">Life at froiden</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-4">
                        <h3>
                            <button className="link-button">Why us?</button>
                        </h3>
                        <ul>
                            <li>
                                <Link to="/why-us/technologies">Technologies</Link>
                            </li>
                            <li>
                                <Link to="/why-us/process">Process</Link>
                            </li>
                            <li>
                                <Link to="/why-us/coding-standards">Coding Standard</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-4">
                        <h3>
                            <button className="link-button">Policies</button>
                        </h3>
                        <ul>
                            <li>
                                <Link to="/policy/privacy">Privacy</Link>
                            </li>
                            <li>
                                <Link to="/policy/terms-of-use">Terms of use</Link>
                            </li>
                            <li>
                                <Link to="/policy/refund-policy">Refund policy</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <h3>
                            <Link to="/contact-us">Contact us</Link>
                        </h3>
                    </div>
                    <div className="col-sm-4">
                        <h3>
                            <Link to="/careers">Careers</Link>
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    </View>
);

export default Sitemap;
