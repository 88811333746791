import React from 'react';

import { Link } from 'react-router-dom';
import View from './View';

const History = () => (
    <View>
        <section className="history-section-header header-transparent-bg">
            <div className="container-fluid">
                <div className="row header-container">
                    <div className="col-xs-12">
                        <h1 className="page-heading contact-heading">History</h1>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/about/overview">About</Link>
                            </li>
                            <li className="breadcrumb-item active">History</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section className="content-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-content">
                            <p>
                                The history of Froiden is not at all what you would expect. <b>It didn't start as:</b>
                                <em>
                                    "Four friends sitting at the coffee table one day came up with an idea of starting a company that did
                                    things differently, and thus was Froiden born"
                                </em>
                                .
                            </p>

                            <p>
                                Froiden actually started as something different and ended up something else. The name "Froiden" was coined
                                for a social networking site that a group of seven people planned to develop during college days in 2010.
                                Two of those seven, Ajay and Shashank, are now co-founders of Froiden. The idea of social networking site
                                didn't work out, as they couldn't think of anything "different" they could do that already existing social
                                networks didn't already do. They stopped working on the site, but the name <b>"Froiden"</b> remained alive.
                            </p>

                            <p>
                                During initial years, the founding members were mostly involved in freelancing work. They had a passion for
                                working with web technologies and working on projects from freelancer.com was the easiest way to live it.
                                They started working under the name Froiden and thus Froiden was born.
                            </p>
                            <p>
                                While working as freelancers, they decided to create some products of their own. <b>Froiden MagMe</b> was
                                the first one. It was a Facebook Application that allowed users to create covers of world famous magazines
                                with their own photos featuring on them. It was a well-appreciated product, which, thought had a slow start,
                                ended up having over one lakh users in next few years.
                            </p>

                            <p>
                                During the same time, they also developed not-so-creative Facebook applications which became very popular
                                and reached a total user-base of over 50 lakh users, before Facebook started taking down this kind of
                                applications. Froiden's official page likes had reached over 8 lakh by then.
                            </p>

                            <p>
                                Froiden, however, was still not what it is today. Ajay and Shashank completed their graduation. Abhinav took
                                up a job in a start-up, while Rakesh was still in college. Shashank later joined Indian Institute of
                                Technology, Roorkee to pursue Master of Technology in Computer Science. A lot of changes happened in the
                                thinking of founders after that. They finally realised that freelancing was undermining their potential and
                                they needed to build a reputed company, taking up more challenging projects and building popular products.
                            </p>

                            <p>
                                In March 2013, the founders decided to build Froiden as an outsourcing service provider, besides creating
                                its own products. They decided to slowly give up freelancing. Over the months, they planned and worked on
                                building Froiden as a brand. They shifted to Jaipur and started working on some ideas for their own
                                products, the first step in building a reputation.
                            </p>

                            <p>
                                After hard work and planning of six months, the founders got Froiden incorporated as a private limited
                                company on 26th March 2014. Quickly following this was the inauguration of the first office of Froiden on
                                13th April 2014 in Malviya Nagar, Jaipur.{' '}
                            </p>

                            <p>This is how Froiden, as it is today, came into existence. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </View>
);

export default History;
