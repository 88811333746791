import React from 'react';
import { Link } from 'react-router-dom';
import View from './View';

const Services = () => (
    <View>
        <section className="service-section-header header-transparent-bg">
            <div className="container-fluid">
                <div className="row header-container">
                    <div className="col-xs-12">
                        <h1 className="page-heading service-heading">Services</h1>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Services</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section className="service-section web-application-bg">
            <div className="container-fluid">
                <div className="row content-container">
                    <div className="col-sm-12 col-md-6 services-container">
                        <h2 className="services-heading text-xs-center text-md-left">Web Applications</h2>
                        <p className="services-heading-content">
                            We offer services for the development of modern web applications using latest{' '}
                            <Link to="/why-us/technologies">technologies</Link> and <Link to="/why-us/process">processes</Link>. We can help
                            you <strong>develop entire applications from scratch</strong>,{' '}
                            <strong>add features to existing products</strong> or{' '}
                            <strong>migrate old applications to new technologies</strong>. Our exclusive focus on web applications helps us
                            deliver better end products.
                        </p>
                        <p className="services-heading-content">
                            In past, we have worked with clients from across the world and successfully delivered them applications of vast
                            variety, including, HR Management Systems, Content Management Systems, Customer Relationship Management Systems,
                            Billing and Payment Systems, and more.
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="m-x-auto text-md-center text-sm-center text-xs-center">
                            <img className="web-image" src="/img/application.svg" alt="Web Applications" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="service-section designing">
            <div className="container-fluid">
                <div className="row content-container">
                    <div className="col-sm-12 col-md-6 services-container pull-md-right">
                        <h2 className="services-heading text-xs-center text-md-left">UX/UI and Graphic Designing</h2>
                        <p className="services-heading-content">
                            With help of our experienced and creative designing team, we create beautiful and innovative{' '}
                            <strong>UI/UX designs</strong> that appeal to your customers. UX/UI designing is an important part of our
                            development process and we take every interface of your application very seriously. That is why our designs are
                            easy to use and beautiful to look at.
                        </p>
                    </div>

                    <div className="col-sm-12 col-md-6 pull-md-left">
                        <div className="m-x-auto text-md-center text-sm-center text-xs-center">
                            <img className="design-image" src="/img/creative-designning.svg" alt="creative designning " />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </View>
);

export default Services;
