import React from 'react';
import { Link } from 'react-router-dom';
import View from './View';
class OverView extends React.Component {
    render() {
        return (
            <View>
                <section className="history-section-header header-transparent-bg">
                    <div className="container-fluid">
                        <div className="row header-container">
                            <div className="col-xs-12">
                                <h1 className="page-heading contact-heading">Overview</h1>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/about/overview">About</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Overview</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2 className="main-heading sea-green">Overview</h2>
                                <div className="page-content">
                                    <p>
                                        Froiden is an outsourcing services provider for small and medium businesses, based in{' '}
                                        <a href="https://en.wikipedia.org/wiki/Jaipur" target="_blank" rel="noopener noreferrer">
                                            Jaipur, Rajasthan
                                        </a>
                                        . It is a young company incorporated in the year 2014.
                                    </p>

                                    <p>
                                        We exclusively focus on providing services related to web applications. We do have plans for
                                        expanding to other emerging domains in near future.
                                    </p>

                                    <p>
                                        We are not limited to providing outsourcing services. We have equal focus on developing our own
                                        products. While our products also are web applications and supporting mobile applications, we do not
                                        focus on any particular subject or industry. Our products tend to provide solutions to common
                                        problems faced by people. We do not develop products if similar ones are already in the market,
                                        unless the existing products can be greatly improved upon.
                                    </p>
                                    <br />
                                </div>

                                <h2 className="main-heading sea-green">Difference Matters</h2>
                                <div className="page-content">
                                    <p>
                                        While working with Froiden, one thing that you will experience the most is the difference from
                                        others in the way we work and see things, and why is this difference so important.
                                    </p>

                                    <p>
                                        Even smallest of things done differently can result in exceptional outcomes. This is what drives us
                                        to do things the way we think is right, and not just going by the book. This leads to innovations
                                        you find at Froiden.
                                    </p>
                                </div>

                                <h2 className="main-heading sea-green">More About Froiden</h2>
                                <div className="page-content">
                                    <p>Here are some links to learn more about us:</p>

                                    <ul>
                                        <li>
                                            <Link to="/about/history">History</Link>: The story of Froiden - how it started and what lies
                                            ahead
                                        </li>
                                    </ul>
                                </div>

                                <h2 className="main-heading sea-green">The Road Ahead</h2>
                                <div className="page-content">
                                    <p>
                                        At the time this site was published, Froiden was about to complete three years. It had lived the
                                        hardest period of a startup.
                                    </p>

                                    <p>
                                        Our journey of three years has made us stronger and braver. Our team has grown to 14 members and
                                        together have achieved many milestones in terms of revenue.
                                    </p>
                                    <p>
                                        Our goal for next few years is to take our products to new heights. To support that, we will keep
                                        growing our services business. We believe in <em>small team - big impact</em>, so we will expand our
                                        team to a limit.
                                    </p>
                                    <p>
                                        Lastly, and obviously, we will keep working on new technologies and ideas, build new products, focus
                                        on innovation and build Froiden as a brand.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </View>
        );
    }
}

export default OverView;
