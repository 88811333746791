import React from 'react';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Busy from '../includes/Busy';
import View from './View';
import data from './testimonial.json';

class TestimonialComponent extends React.Component {
    state = {
        testimonials: data,
        busy: true,
    };

    componentDidMount() {
        this.setState({ busy: false });
    }

    // fetchTestimonials = () => {
    //     fetch('./testimonial.json')
    //         .then((response) => response.json())
    //         .then((data) => {
    //             this.setState({ testimonials: data.data, busy: false });
    //         });
    // };

    render() {
        const Testimonial = (props) => (
            <div className="item" itemProp="review" itemScope itemType="http://schema.org/Review">
                <div className="testimonial-text" itemProp="name">
                    {props.feedback.testimonial}
                </div>
                <div className="client-name" itemProp="author">
                    {props.feedback.client_name}
                </div>
                <div className="client-place">{props.feedback.company}</div>
            </div>
        );

        const TestimonialList = () =>
            this.state.testimonials.map(function (feedback) {
                return <Testimonial key={feedback.id} feedback={feedback} />;
            });

        return (
            <View>
                <Busy busy={this.state.busy} />
                {this.state.testimonials.length > 0 && (
                    <OwlCarousel className="owl-theme" loop margin={10} items={1} nav>
                        <TestimonialList />
                    </OwlCarousel>
                )}
            </View>
        );
    }
}

export default TestimonialComponent;
