import React from 'react';
import { Link } from 'react-router-dom';
import View from './View';

const TermsOfUse = () => (
    <View>
        <section className="policy-section-header header-transparent-bg">
            <div className="container-fluid">
                <div className="row header-container">
                    <div className="col-xs-12">
                        <h1 className="page-heading contact-heading">Terms of Use</h1>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active">Terms of Use</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section className="content-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <h2 className="main-heading dark-pink">Terms of Use for www.froiden.com</h2>
                        <div className="page-content">
                            The use of any product, service or feature (the "Materials") available through the internet web sites accessible
                            at Froiden.com (the "Web Site") by any user of the Web Site ("You" or "Your" hereafter) shall be governed by the
                            following terms of use:
                            <br />
                            This Web Site is provided by Froiden, a partnership awaiting registration with Government of India, and shall be
                            used for informational purposes only. By using the Web Site or downloading Materials from the Web Site, You
                            hereby agree to abide by the terms and conditions set forth in this Terms of Use. In the event of You not
                            agreeing to these terms and conditions, You are requested by Froiden not to use the Web Site or download
                            Materials from the Web Site. This Web Site, including all Materials present (excluding any applicable third
                            party materials), is the property of Froiden and is copyrighted and protected by worldwide copyright laws and
                            treaty provisions. You hereby agree to comply with all copyright laws worldwide in Your use of this Web Site and
                            to prevent any unauthorized copying of the Materials. Froiden does not grant any express or implied rights under
                            any patents, trademarks, copyrights or trade secret information.
                            <br />
                            Froiden has business relationships with many customers, suppliers, governments, and others. For convenience and
                            simplicity, words like joint venture, partnership, and partner are used to indicate business relationships
                            involving common activities and interests, and those words may not indicate precise legal relationships.
                        </div>
                        <h2 className="main-heading dark-pink"> LIMITED LICENSE:</h2>
                        <div className="page-content">
                            Subject to the terms and conditions set forth in these Terms of Use, Froiden grants You a non-exclusive,
                            non-transferable, limited right to access, use and display this Web Site and the Materials thereon. You agree
                            not to interrupt or attempt to interrupt the operation of the Web Site in any manner. Unless otherwise
                            specified, the Web Site is for Your personal and non-commercial use. You shall not modify, copy, distribute,
                            transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any
                            information, software, products or services obtained from this Web Site.
                        </div>
                        <h2 className="main-heading dark-pink">THIRD PARTY CONTENT</h2>
                        <div className="page-content">
                            The Web Site makes information of third parties available, including articles, analyst reports, news reports and
                            company information, including any regulatory authority, content licensed under Content Licensed under Creative
                            Commons Attribution License and other data from external sources (the "Third Party Content"). You acknowledge
                            and agree that the Third Party Content is not created or endorsed by Froiden. The provision of Third Party
                            Content is for general informational purposes only and does not constitute a recommendation or solicitation to
                            purchase or sell any securities or shares or to make any other type of investment or investment decision. In
                            addition, the Third Party Content is not intended to provide tax, legal or investment advice. You acknowledge
                            that the Third Party Content provided to You is obtained from sources believed to be reliable, but that no
                            guarantees are made by Froiden or the providers of the Third Party Content as to its accuracy, completeness,
                            timeliness. You agree not to hold Froiden, any business offering products or services through the Web Site or
                            any provider of Third Party Content liable for any investment decision or other transaction You may make based
                            on Your reliance on or use of such data, or any liability that may arise due to delays or interruptions in the
                            delivery of the Third Party Content for any reason
                            <br />
                            By using any Third Party Content, You may leave this Web Site and be directed to an external website, or to a
                            website maintained by an entity other than Froiden. If You decide to visit any such site, You do so at Your own
                            risk and it is Your responsibility to take all protective measures to guard against viruses or any other
                            destructive elements. Froiden makes no warranty or representation regarding, and does not endorse, any linked
                            web sites or the information appearing thereon or any of the products or services described thereon. Links do
                            not imply that Froiden or this Web Site sponsors, endorses, is affiliated or associated with, or is legally
                            authorized to use any trademark, trade name, logo or copyright symbol displayed in or accessible through the
                            links, or that any linked site is authorized to use any trademark, trade name, logo or copyright symbol of
                            Froiden or any of its affiliates or subsidiaries. You hereby expressly acknowledge and agree that the linked
                            sites are not under the control of Froiden and Froiden is not responsible for the contents of any linked site or
                            any link contained in a linked site, or any changes or updates to such sites. Froiden is not responsible for
                            webcasting or any other form of transmission received from any linked site. Froiden is providing these links to
                            You only as a convenience, and the inclusion of any link shall not be construed to imply endorsement by Froiden
                            in any manner of the website.
                        </div>
                        <h2 className="main-heading dark-pink">NO WARRANTIES</h2>
                        <div className="page-content">
                            THIS WEB SITE, THE INFORMATION AND MATERIALS ON THE SITE, AND ANY SOFTWARE MADE AVAILABLE ON THE WEB SITE, ARE
                            PROVIDED "AS IS" WITHOUT ANY REPRESENTATION OR WARRANTY, EXPRESS OR IMPLIED, OF ANY KIND, INCLUDING, BUT NOT
                            LIMITED TO, WARRANTIES OF MERCHANTABILITY, NON INFRINGEMENT, OR FITNESS FOR ANY PARTICULAR PURPOSE. THERE IS NO
                            WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, REGARDING THIRD PARTY CONTENT. INSPITE OF FROIDEN BEST ENDEAVOURS,
                            THERE IS NO WARRANTY ON BEHALF OF FROIDEN THAT THIS WEB SITE WILL BE FREE OF ANY COMPUTER VIRUSES. SOME
                            JURISDICTIONS DO NOT ALLOW FOR THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO
                            YOU.
                        </div>

                        <h2 className="main-heading dark-pink">LIMITATION OF DAMAGES:</h2>
                        <div className="page-content">
                            IN NO EVENT SHALL FROIDEN OR ANY OF ITS SUBSIDIARIES OR AFFILIATES BE LIABLE TO ANY ENTITY FOR ANY DIRECT,
                            INDIRECT, SPECIAL, CONSEQUENTIAL OR OTHER DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS
                            INTERRUPTION, LOSS OF INFORMATION OR PROGRAMS OR OTHER DATA ON YOUR INFORMATION HANDLING SYSTEM) THAT ARE
                            RELATED TO THE USE OF, OR THE INABILITY TO USE, THE CONTENT, MATERIALS, AND FUNCTIONS OF THIS WEB SITE OR ANY
                            LINKED WEB SITE, EVEN IF FROIDEN IS EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                        </div>

                        <h2 className="main-heading dark-pink">DISCLAIMER:</h2>
                        <div className="page-content">
                            THE WEB SITE MAY CONTAIN INACCURACIES AND TYPOGRAPHICAL AND CLERICAL ERRORS. FROIDEN EXPRESSLY DISCLAIMS ANY
                            OBLIGATION(S) TO UPDATE THIS WEBSITE OR ANY OF THE MATERIALS ON THIS WEBSITE. FROIDEN DOES NOT WARRANT THE
                            ACCURACY OR COMPLETENESS OF THE MATERIALS OR THE RELIABILITY OF ANY ADVICE, OPINION, STATEMENT OR OTHER
                            INFORMATION DISPLAYED OR DISTRIBUTED THROUGH THE WEB SITE. YOU ACKNOWLEDGE THAT ANY RELIANCE ON ANY SUCH
                            OPINION, ADVICE, STATEMENT, MEMORANDUM, OR INFORMATION SHALL BE AT YOUR SOLE RISK. FROIDEN RESERVES THE RIGHT,
                            IN ITS SOLE DISCRETION, TO CORRECT ANY ERRORS OR OMISSIONS IN ANY PORTION OF THE WEB SITE. FROIDEN MAY MAKE ANY
                            OTHER CHANGES TO THE WEB SITE, THE MATERIALS AND THE PRODUCTS, PROGRAMS, SERVICES OR PRICES (IF ANY) DESCRIBED
                            IN THE WEB SITE AT ANY TIME WITHOUT NOTICE. THIS WEB SITE IS FOR INFORMATIONAL PURPOSES ONLY AND SHOULD NOT BE
                            CONSTRUED AS TECHNICAL ADVICE OF ANY MANNER.
                        </div>

                        <h2 className="main-heading dark-pink">UNLAWFUL AND/OR PROHIBITED USE OF THE WEB SITE</h2>
                        <div className="page-content">
                            As a condition of Your use of the Web Site, You shall not use the Web Site for any purpose(s) that is unlawful
                            or prohibited by the Terms of Use. You shall not use the Web Site in any manner that could damage, disable,
                            overburden, or impair any Froiden server, or the network(s) connected to any Froiden server, or interfere with
                            any other party's use and enjoyment of any services associated with the Web Site. You shall not attempt to gain
                            unauthorized access to any section of the Web Site, other accounts, computer systems or networks connected to
                            any Froiden server or to any of the services associated with the Web Site, through hacking, password mining or
                            any other means. You shall not obtain or attempt to obtain any Materials or information through any means not
                            intentionally made available through the Web Site.
                        </div>
                        <h2 className="main-heading dark-pink">INDEMNITY:</h2>
                        <div className="page-content">
                            You agree to indemnify and hold harmless Froiden, its subsidiaries and affiliates from any claim, cost, expense,
                            judgement or other loss relating to Your use of this Web Site in any manner, including without limitation of the
                            foregoing, any action You take which is in violation of the terms and conditions of these Terms of Use and
                            against any applicable law.
                        </div>
                        <h2 className="main-heading dark-pink">CHANGES:</h2>
                        <div className="page-content">
                            Froiden reserves the rights, at its sole discretion, to change, modify, add or remove any portion of these Terms
                            of Use in whole or in part, at any time. Changes in these Terms of Use will be effective when notice of such
                            change is posted. Your continued use of the Web Site after any changes to these Terms of Use are posted will be
                            considered acceptance of those changes. Froiden may terminate, change, suspend or discontinue any aspect of the
                            Web Site, including the availability of any feature(s) of the Web Site, at any time. Froiden may also impose
                            limits on certain features and services or restrict Your access to certain sections or all of the Web Site
                            without notice or liability. You hereby acknowledge and agree that Froiden may terminate the authorization,
                            rights and license given above at any point of time at its own sole discretion and upon such termination; You
                            shall immediately destroy all Materials.
                        </div>
                        <h2 className="main-heading dark-pink">INTERNATIONAL USERS AND CHOICE OF LAW:</h2>
                        <div className="page-content">
                            This Site is controlled, operated and administered by Froiden from within India. Froiden makes no representation
                            that Materials on this Web Site are appropriate or available for use at any other location(s) outside India. Any
                            access to this Web Site from territories where their contents are illegal is prohibited. You may not use the Web
                            Site or export the Materials in violation of any applicable export laws and regulations. If You access this Web
                            Site from a location outside India, You are responsible for compliance with all local laws.
                            <br />
                            These Terms of Use shall be governed by the laws of India, without giving effect to its conflict of laws
                            provisions. You agree that the appropriate court(s) in Bangalore, India, will have the exclusive jurisdiction to
                            resolve all disputes arising under these Terms of Use and You hereby consent to personal jurisdiction in such
                            forum.
                            <br />
                            These Terms of Use constitutes the entire agreement between Froiden and You with respect to Your use of the Web
                            Site. Any claim You may have with respect to Your use of the Web Site must be commenced within one (1) year of
                            the cause of action. If any provision(s) of this Terms of Use is held by a court of competent jurisdiction to be
                            contrary to law then such provision(s) shall be severed from this Terms of Use and the other remaining
                            provisions of this Terms of Use shall remain in full force and effect.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </View>
);

export default TermsOfUse;
