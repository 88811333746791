import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './sass/main.scss';

import Home from './components/Home';
import Products from './components/Products';
import Header from './includes/Header';
import Footer from './includes/Footer';
import OverView from './components/OverView';
import Technology from './components/Technology';
import History from './components/History';
import Privacy from './components/Privacy';
import TermsOfUse from './components/TermsOfUse';
import Services from './components/Services';
import Process from './components/Process';
import CodingStandard from './components/CodingStandard';
import RefundPolicy from './components/RefundPolicy';
import Team from './components/Team';
import Career from './components/Career/Career';
import ContactUs from './components/ContactUs/ContactUs';

import NotFound from './components/NotFound';
import Sitemap from './components/Sitemap';
import LifeAtFroiden from './components/LifeAtFroiden/LifeAtFroiden';
import CareerApplyNow from './components/Career/CareerApplyNow';
import DocumentMeta from 'react-document-meta';
import MetaData from './components/MetaData';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-50749774-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends React.Component {
    MatchUrl(url) {
        let match = '/careers/apply-now';
        if (url.indexOf(match) > -1) {
            return match + '/:id';
        }

        return url;
    }

    render() {
        return (
            <div>
                {/*---------Header-------------*/}
                <Header />
                {/*---------Header-------------*/}

                <Route
                    render={({ location }) => (
                        <div>
                            <DocumentMeta {...MetaData[this.MatchUrl(location.pathname)]} />

                            <TransitionGroup>
                                <CSSTransition key={location.key} classNames="fade" timeout={300}>
                                    <Switch location={location}>
                                        <Route exact path="/" key="home" component={Home} />
                                        <Route exact path="/products" component={Products} />

                                        <Route path="/about/overview" component={OverView} />
                                        <Route path="/about/history" component={History} />
                                        <Route path="/about/team" component={Team} />
                                        <Route path="/about/life-at-froiden" component={LifeAtFroiden} />

                                        <Route path="/policy/privacy" component={Privacy} />
                                        <Route path="/policy/terms-of-use" component={TermsOfUse} />
                                        <Route path="/policy/refund-policy" component={RefundPolicy} />
                                        <Route path="/services" component={Services} />

                                        <Route path="/why-us/technologies" component={Technology} />
                                        <Route path="/why-us/process" component={Process} />
                                        <Route path="/why-us/coding-standards" component={CodingStandard} />

                                        <Route exact path="/careers" component={Career} />
                                        <Route path="/careers/apply-now/:id" component={CareerApplyNow} />

                                        <Route path="/contact-us" component={ContactUs} />

                                        <Route path="/404" component={NotFound} />
                                        <Route path="/sitemap" component={Sitemap} />
                                        <Redirect from="*" to="/404" />
                                    </Switch>
                                </CSSTransition>
                            </TransitionGroup>
                        </div>
                    )}
                />

                {/*----------Footer-----------*/}
                <Footer />
                {/*----------Footer-----------*/}
            </div>
        );
    }
}

export default App;
