import React from 'react';
import { Link } from 'react-router-dom';
import View from './View';

const Process = () => (
    <View>
        <section className="process-section-header header-transparent-bg">
            <div className="container-fluid">
                <div className="row header-container">
                    <div className="col-xs-12">
                        <h1 className="page-heading contact-heading">Process</h1>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/why-us/technologies">Why us?</Link>
                            </li>
                            <li className="breadcrumb-item active">Process</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section className="content-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-7">
                        <h2 className="main-heading sea-green">Agile Development</h2>
                        <div className="page-content">
                            <p>
                                We follow Agile Software Development methodology. We have tried many methodologies in last few years and
                                Agile is the one that works for us.
                            </p>
                            <p>
                                The agile methodology provides many advantages over traditional methods. It requires lesser planning and we
                                can start working much faster. It enables us to provide regular updates to clients. It also enables us to be
                                more responsive to bugs and client feedback.
                            </p>
                            <p>
                                We work with many products assisting in agile project management, like{' '}
                                <a href="https://www.atlassian.com/software/jira" target="_blank" rel="noopener noreferrer">
                                    JIRA
                                </a>
                                ,{' '}
                                <a href="https://trello.com/" target="_blank" rel="noopener noreferrer">
                                    Trello
                                </a>{' '}
                                and{' '}
                                <a href="https://gitlab.com/" target="_blank" rel="noopener noreferrer">
                                    Gitlab Issue Management
                                </a>
                                . Which product should we work on is mostly decided by the client. Given a choice, however, we prefer Gitlab
                                Issue Management.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="m-x-auto process-img-container m-t-50">
                            <img className="img-fluid" src="/img/process/agile-development.svg" alt="Agile Development" />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <hr />
                    </div>
                </div>
                <div className="row m-t-30">
                    <div className="col-md-7">
                        <h2 className="main-heading sea-green">API Driven Applications</h2>
                        <div className="page-content">
                            <p>
                                Our applications are mostly REST API driven. We create a REST API in Laravel/Lumen, and all the data
                                handling is done by the API. The UI of the applications is created using one of the popular frontend
                                frameworks -{' '}
                                <a href="https://vuejs.org/" target="_blank" rel="noopener noreferrer">
                                    Vue.js
                                </a>{' '}
                                or{' '}
                                <a href="https://angular.io/" target="_blank" rel="noopener noreferrer">
                                    Angular
                                </a>
                                . The UI communicates with API through a common service.
                            </p>
                            <p>
                                One of the biggest advantages of this approach is that{' '}
                                <strong>same API can be used for both desktop web application and mobile application</strong>, essentially
                                saving you half of mobile application development cost. Apart from this, it makes application easier to
                                manage, test and less error prone.
                            </p>
                            <p>
                                We have even developed a plugin for the development of advanced REST APIs in Laravel:
                                <a href="https://github.com/Froiden/laravel-rest-api" target="_blank" rel="noopener noreferrer">
                                    https://github.com/Froiden/laravel-rest-api
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="m-x-auto process-img-container m-t-35">
                            <img className="img-fluid" src="/img/process/api.svg" alt="API Driven Applications" />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <hr />
                    </div>
                </div>
                <div className="row m-t-30">
                    <div className="col-md-7">
                        <h2 className="main-heading sea-green">
                            <span name="modular_approach"></span>Modular Approach
                        </h2>
                        <div className="page-content">
                            <p>
                                We develop applications in form of multiple independent modules that share data through interfaces. For
                                example, we use following Laravel plugin to modularize backend code:
                                <a href="https://github.com/nWidart/laravel-modules" target="_blank" rel="noopener noreferrer">
                                    https://github.com/nWidart/laravel-modules
                                </a>
                                .
                            </p>
                            <p>As an example, in a typical SaaS product, User Management, Billing, Reporting, etc. can be modules.</p>
                            <p>
                                Modularizing application greatly helps in managing code in large applications. It also makes components
                                independent, which can be enabled/disabled on the fly.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="m-x-auto process-img-container m-t-20">
                            <img className="img-fluid" src="/img/process/modular-approach.svg" alt="Modular Approach" />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <hr />
                    </div>
                </div>
                <div className="row m-t-30">
                    <div className="col-md-7">
                        <h2 className="main-heading sea-green">Scalability</h2>
                        <div className="page-content">
                            <p>
                                Thanks to the advances in cloud technologies in recent times, it has become much easier to scale your web
                                application. We leverage the power of various cloud platforms, like,
                                <strong>Amazon AWS and Microsoft Azure</strong>, to enable your applications to scale easily.
                            </p>
                            <p>
                                Using products like AWS S3 for data storage, CDN for static content delivery, load balancers for handling
                                heavy traffic, Amazon EC2 for hosting code and Amazon SMS for email delivery, we enable your applications to
                                be ready to handle millions of users right from start!
                            </p>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="m-x-auto process-img-container m-t-15">
                            <img className="img-fluid" src="/img/process/scalability.svg" alt="Scalability" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </View>
);

export default Process;
