import React from 'react';

function CareerFormComponent(props) {
    return (
        <form className="contact-form-label" onSubmit={props.submitForm}>
            <div className="col-sm-12">
                <div className="md-form">
                    <input
                        type="text"
                        name="name"
                        className={'form-control ' + (props.errors['name'] ? 'invalid' : '')}
                        onChange={props.handleChange}
                        value={props.fields.name}
                    />
                    <label htmlFor="name">Name</label>
                    <span className="form-error">{props.errors['name']}</span>
                </div>

                <div className="md-form">
                    <input
                        type="email"
                        name="email"
                        className={'form-control ' + (props.errors['email'] ? 'invalid' : '')}
                        onChange={props.handleChange}
                        value={props.fields.email}
                    />
                    <label htmlFor="email">Email Address</label>
                    <span className="form-error">{props.errors['email']}</span>
                </div>

                <div className="md-form">
                    <input
                        type="text"
                        name="phone"
                        className={'form-control ' + (props.errors['phone'] ? 'invalid' : '')}
                        onChange={props.handleChange}
                        value={props.fields.phone}
                    />
                    <label htmlFor="phone">Phone</label>
                    <span className="form-error">{props.errors['phone']}</span>
                </div>

                <div className="md-form">
                    <textarea
                        name="cover"
                        className={'md-textarea ' + (props.errors['cover'] ? 'invalid' : '')}
                        onChange={props.handleChange}
                        value={props.fields.cover}
                    />
                    <label htmlFor="cover">Cover Letter</label>
                    <span className="form-error">{props.errors['cover']}</span>
                </div>

                <div className="md-form">
                    <textarea name="whyFroiden" className="md-textarea" />
                    <label htmlFor="whyFroiden">Why do you want to work with Froiden?</label>
                    <span className="form-error">{props.errors['whyFroiden']}</span>
                </div>

                <div className="file-field input-field">
                    <div className="btn btn-primary">
                        <span>
                            <i className="fa fa-upload" aria-hidden="true" />
                        </span>
                        <input type="file" onChange={props.onChange} />
                    </div>
                    <div className="file-path-wrapper">
                        <input className="file-path" type="text" placeholder="Upload Resume" />
                    </div>
                    <span className="form-error">{props.errors['resume']}</span>
                </div>
            </div>
            <div className="col-sm-12 text-left">
                <button id="" className="btn btn-success" type="submit" disabled={props.isLoading}>
                    {!props.isLoading && <span>Submit</span>}

                    {props.isLoading && (
                        <div>
                            <i className="fa fa-refresh fa-spin" /> <span> Submitting</span>
                        </div>
                    )}
                </button>
            </div>
        </form>
    );
}

export default CareerFormComponent;
