import React from 'react';

const Busy = (props) => {
    if (props.busy) {
        return (
            <div className="col-sm-12 row-container">
                <h4 className="text-xs-center">
                    <img src="/img/spinner.svg" alt="Busy Loader" />
                </h4>
            </div>
        );
    }

    return null;
};

export default Busy;
