import React from 'react';
import { API_JOBS } from '../../Config';
import CareerFormComponent from './CareerFormComponent';
import View from '../View';

class CareerFormContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {
                name: '',
                phone: '',
                email: '',
                cover: '',
                whyFroiden: '',
                resume: '',
            },
            errors: {},
            success: '',
            done: false,
            isLoading: false,
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        this.setState((prevState) => ({
            fields: {
                ...prevState.fields,
                resume: files[0],
            },
        }));
    }

    handleValidation() {
        // For submitting Button
        this.setState({ isLoading: true });

        let errors = {};
        let formIsValid = true;

        const fields = this.state.fields;
        if (!fields.name) {
            formIsValid = false;
            errors['name'] = 'The name is required.';
        }

        if (!fields.email) {
            formIsValid = false;
            errors['email'] = 'The email is required.';
        }

        if (typeof fields.email !== 'undefined') {
            let lastAtPos = fields.email.lastIndexOf('@');
            let lastDotPos = fields.email.lastIndexOf('.');

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields.email.indexOf('@@') === -1 &&
                    lastDotPos > 2 &&
                    fields.email.length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors['email'] = 'Enter correct email';
            }
        }

        if (!fields.cover) {
            formIsValid = false;
            errors['cover'] = 'The cover is required.';
        }

        if (!fields.resume) {
            formIsValid = false;
            errors['resume'] = 'The resume is required.';
        }

        this.setState({ errors: errors, isLoading: false });

        return formIsValid;
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState((prevState) => ({
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };

    submitForm = (event) => {
        event.preventDefault();

        const jobID = this.props.jobID;
        let self = this;

        if (this.handleValidation()) {
            this.setState({ isLoading: true });

            const fields = this.state.fields;

            let formData = new FormData();
            formData.append('name', fields.name);
            formData.append('email', fields.email);
            formData.append('phone', fields.phone);
            formData.append('cover', fields.cover);
            formData.append('whyFroiden', fields.whyFroiden);
            formData.append('resume', fields.resume);

            fetch(API_JOBS + '/' + jobID + '/apply', {
                method: 'POST',
                body: formData,
            })
                .then(function (res) {
                    return res.json();
                })
                .then(function (data) {
                    self.setState({ success: true, done: true, isLoading: false });
                });
        }
    };

    render() {
        const Alert = () => {
            if (this.state.success) {
                return (
                    <div>
                        <h1 className="form-title">
                            <i className="fa fa-check icon-color green" /> Thank You!
                        </h1>
                        <p>
                            Thank you for submitting the job application. Soon our team will review your resume. Once your resume is
                            selected, you will get a call from our end.
                        </p>
                    </div>
                );
            }

            if (this.state.success === false) {
                return (
                    <div className="alert alert-danger">
                        An error occurred. Please try again! If problem persists, please reach us at contactus@froiden.com.
                    </div>
                );
            }

            return null;
        };

        return (
            <View>
                <div className="row">
                    <div className="col-sm-12">
                        {!this.state.done && (
                            <h2 className="form-title pull-xs-left">
                                <i className="fa fa-pencil icon-color" /> Fill the Form
                            </h2>
                        )}

                        <button type="button" className="btn btn-rounded blue pull-xs-right close sidebar-close close-btn-reset">
                            <i className="lnr lnr-cross" />
                        </button>
                    </div>
                </div>

                <Alert />

                {/*data={this.state} to grab props.data.name*/}
                {/*Or {...this.state} to grab props.name*/}
                {!this.state.done && (
                    <CareerFormComponent
                        handleChange={this.handleChange}
                        submitForm={this.submitForm}
                        onChange={this.onChange}
                        {...this.state}
                    />
                )}
            </View>
        );
    }
}

export default CareerFormContainer;
