import React from 'react';
import { Link } from 'react-router-dom';
import View from './View';

const CodingStandard = () => (
    <View>
        <section className="coding-standard-section header-transparent-bg">
            <div className="container-fluid">
                <div className="row header-container">
                    <div className="col-xs-12">
                        <h1 className="page-heading contact-heading">Coding Standard</h1>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/why-us/technologies">Why us?</Link>
                            </li>
                            <li className="breadcrumb-item active">Coding Standard</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>

        <section className="content-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-content">
                            <blockquote className="blockquote">
                                <p className="mb-0">
                                    Any fool can write code that a computer can understand. Good programmers write code that humans can
                                    understand.
                                </p>
                                <footer className="blockquote-footer">Martin Fowler</footer>
                            </blockquote>
                            <p>
                                Indeed, writing good code is one of the most important factors in making a successful application. Good code
                                makes it very easy to maintain the application and also reduces the chances of bugs.
                            </p>
                            <p>
                                We take coding style very seriously at Froiden. With the help of modern lint tools, we also enforce it
                                throughout our organization.
                            </p>
                            <p>&#160;</p>
                            <p>
                                <img className="img-fluid" src="http://imgs.xkcd.com/comics/goto.png" alt="Coding Standard" />
                            </p>
                        </div>

                        <h2 className="main-heading purple-color">Code Linting</h2>
                        <div className="page-content">
                            <p>
                                We enforce coding standard using lint tools, like,
                                <a href="https://github.com/squizlabs/PHP_CodeSniffer" target="_blank" rel="noopener noreferrer">
                                    PHP Code Sniffer
                                </a>
                                ,
                                <a href="https://phpmd.org/" target="_blank" rel="noopener noreferrer">
                                    PHP Mess Detector
                                </a>
                                ,
                                <a href="http://eslint.org/" target="_blank" rel="noopener noreferrer">
                                    ESLint
                                </a>
                                , etc.
                            </p>
                            <p>We have open sourced our linter config files:</p>
                            <ol>
                                <li>
                                    <strong>PHP Standard</strong>:{' '}
                                    <a href="https://github.com/Froiden/PHPStandard" target="_blank" rel="noopener noreferrer">
                                        https://github.com/Froiden/PHPStandard
                                    </a>
                                </li>
                                <li>
                                    <strong>JS Standard</strong>:{' '}
                                    <a href="https://github.com/Froiden/js-standard" target="_blank" rel="noopener noreferrer">
                                        https://github.com/Froiden/js-standard
                                    </a>
                                </li>
                                <li>
                                    <strong>Git Commit Message Hook</strong>:{' '}
                                    <a href="https://github.com/Froiden/git_template" target="_blank" rel="noopener noreferrer">
                                        https://github.com/Froiden/git_template
                                    </a>
                                </li>
                            </ol>
                            <p>
                                While working with Froiden, good code will be least of your concerns. Cheers!{' '}
                                <img
                                    src="https://cdnjs.cloudflare.com/ajax/libs/emojione/2.2.7/assets/png/1f37b.png"
                                    style={{ height: '1rem' }}
                                    alt="While working with Froiden, good code will be least of your concerns. Cheers!"
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </View>
);

export default CodingStandard;
