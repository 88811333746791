import React from 'react';
import { Link } from 'react-router-dom';
import Busy from '../../includes/Busy.js';
import { API_JOBS } from '../../Config';
import View from '../View';

class JobList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            busy: true,
        };
    }

    componentDidMount() {
        fetch(API_JOBS)
            .then((response) => response.json())
            .then((data) => {
                this.setState({ jobs: data.data });
                this.setState({ busy: false });
            });
    }

    render() {
        const Job = (props) => (
            <div className="col-sm-6 col-md-4 col-lg-4">
                <Link
                    style={{ height: '3rem', padding: '0.8rem' }}
                    className="container-box waves-effect waves-button"
                    title={props.job.role}
                    to={'/careers/apply-now/' + props.job.id}
                >
                    {props.job.role}
                </Link>
            </div>
        );

        const JobList = () =>
            this.state.jobs.map(function (job) {
                return <Job key={job.id} job={job} />;
            });

        const CheckJobs = () => {
            if (this.state.jobs.length > 0) {
                return <h2 className="intro-heading text-xs-center">Excited? Apply Now!</h2>;
            }
            if (this.state.jobs.length === 0 && !this.state.busy) {
                return (
                    <h2 className="intro-heading text-xs-center">
                        Sorry, there are no openings right now{' '}
                        <img
                            src="https://cdnjs.cloudflare.com/ajax/libs/emojione/2.2.7/assets/png/1f625.png"
                            alt="Sad Icon"
                            style={{ height: '2rem' }}
                        />
                    </h2>
                );
            }

            return null;
        };

        return (
            <View>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <CheckJobs />
                        </div>
                    </div>
                    <div className="row row-reset">
                        <div className="col-sm-12">
                            <Busy busy={this.state.busy} />

                            <JobList />
                        </div>
                    </div>
                </div>
            </View>
        );
    }
}

export default JobList;
