import React from 'react';
import { Link } from 'react-router-dom';
import View from '../View';
import CuratorWidget from './CuratorWidget';

class LifeAtFroiden extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: true };
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }
    render() {
        return (
            <View>
                <section className="froiden-section-header header-transparent-bg">
                    <div className="container-fluid">
                        <div className="row header-container">
                            <div className="col-xs-12">
                                <h1 className="page-heading contact-heading">Life At Froiden</h1>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/about/overview">About</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Life at Froiden</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container-fluid">
                        <div className="froiden-text">
                            <img src="/img/orange-logo.svg" alt="Froiden Logo" />
                        </div>
                        <div class="col-lg-12">
                            <CuratorWidget feedId="7ed253ca-2a1a-469d-9b55-8420508d5e7c" />
                        </div>
                    </div>
                </section>
            </View>
        );
    }
}

export default LifeAtFroiden;
