let title = ' - Froiden';
let MetaData = {
    '/': {
        title: 'Web Development Jaipur, Web design Jaipur, Graphic design Jaipur India' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Web Development Jaipur, Web design Jaipur, Graphic design Jaipur India' + title,
            },
            name: {
                keywords:
                    'Web Design Jaipur, Website Development in jaipur, Website Designing in Jaipur India, Top Website Development company in Jaipur India, Laravel Development in Jaipur,Angular js development in jaipur , Software development company in jaipur india, Node js development in Jaipur, Vue js Development in Jaipur, React Development in Jaipur, hrm, hr management software, cloud hr software, online hr software, saas hr software, project management system, project management software, payroll management software, pms, appointment management software, appointment management system, recruitment management system, recruitment management software',
                description: 'Froiden is a web development & design service provider based in Jaipur, India. Call us at +91-9660788455',
            },
        },
    },

    '/about/overview': {
        title: 'Overview' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Overview' + title,
            },
            name: {
                keywords: 'Froiden Overview',
                description: 'Froiden Overview. Call us at +91-9660788455',
            },
        },
    },
    '/about/history': {
        title: 'History of Froiden' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'History' + title,
            },
            name: {
                keywords: 'froiden history',
                description:
                    'The history of Froiden is not at all what you would expect. It didn\\\'t start as: "Four friends sitting at the coffee table one day came up with an idea of starting a company that did things differently, and thus was Froiden born"..',
            },
        },
    },
    '/about/team': {
        title: 'Meet Our Amazing Team' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Meet Our Amazing Team' + title,
            },
            name: {
                keywords: 'PHP Developer, Full Stack Developer, Graphic Designer, Web Designer, Jobs in Jaipur, Career in IT in Jaipur',
                description: 'Small Team - Big Impact. We have a dedicated team at Froiden',
            },
        },
    },
    '/about/life-at-froiden': {
        title: 'Life at Froiden' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Life at Froiden' + title,
            },
            name: {
                keywords: 'Life at Froiden',
                description: "See Froiden\\'s Timeline. Call us at +91-9660788455",
            },
        },
    },

    '/products': {
        title: 'Cloud & SAAS Business Software' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Cloud & SAAS Business Software' + title,
            },
            name: {
                keywords:
                    'hrm, hr management software, cloud hr software, online hr software, saas hr software, project management system, project management software, payroll management software, pms, appointment management software, appointment management system, recruitment management system, recruitment management software',
                description: 'We are best in the field of Web Design, Web development in Jaipur, India. Call us at +91-9660788455',
            },
        },
    },

    '/services': {
        title: 'Web development, Web Design' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Web development, Web Design' + title,
            },
            name: {
                keywords: 'Web design, Graphic design, Web development,React, Vuejs, Laravel, Codeignitor',
                description: 'We are best in the field of Web Design, Web development in Jaipur, India. Call us at +91-9660788455',
            },
        },
    },

    '/why-us/technologies': {
        title: 'Development Technologies' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Development Technologies' + title,
            },
            name: {
                keywords: 'Node js projects, Angular js projects, Vue js projects, Laravel Development',
                description: 'Froiden follows the advanced technologies like laravel, vuejs, gulp , npm, angular js, node js etc',
            },
        },
    },
    '/why-us/process': {
        title: 'Development Process we follow' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Development Process we follow' + title,
            },
            name: {
                keywords:
                    'Agile Web Development process, API Driven Applications development Process, Modular Approach Development process, scalable web application',
                description: 'Planning - Wire-frame & UI - Development - Testing - Deployment. Call us at +91-9660788455',
            },
        },
    },
    '/why-us/coding-standards': {
        title: 'Coding Standard we follow' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Coding Standard we follow' + title,
            },
            name: {
                keywords: 'Froiden coding standards, Froiden PHP Standard, Froiden Js Standard',
                description: 'Froiden Follows its own coding standards. Visit Github profile for more information. ',
            },
        },
    },

    '/careers': {
        title: 'Career with us' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Career with us' + title,
            },
            name: {
                keywords:
                    'PHP Developer Jobs, Full Stack Developer Jobs, Graphic Designer jobs, Web Designer Jobs,Node js developer in Jaipur, Laravel developers in Jaipur, Angular js developers in jaipur, Vue js developers in jaipur, Jobs in Jaipur, Career in IT in Jaipur',
                description: "Do you want to be a part of Froiden\\'s journey? Come, join us. Call us at +91-9660788455",
            },
        },
    },
    '/careers/apply-now/:id': {
        title: 'Job Openings at Froiden' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Job Openings at Froiden' + title,
            },
            name: {
                keywords: 'Job Openings in Jaipur',
                description: "Be a part of Froiden\\'s journey? Come, join us. Call us at +91-9660788455",
            },
        },
    },

    '/contact-us': {
        title: 'Contact Us | Web Designing & Web Development Jaipur, India' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Contact Us | Web Designing & Web Development Jaipur, India' + title,
            },
            name: {
                keywords: 'Froiden support, froiden contact us',
                description: 'Froiden provides Best professional web solutions in Jaipur, India. Call us at +91-9660788455',
            },
        },
    },
    '/sitemap': {
        title: 'Sitemap' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Sitemap' + title,
            },
            name: {
                keywords: 'Sitemap',
                description: 'Froiden Sitemap, Call us at +91-9660788455',
            },
        },
    },

    '/policy/terms-of-use': {
        title: 'Terms of use & Condition' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Terms of use & Condition' + title,
            },
            name: {
                keywords: 'Terms of use & Condition froiden',
                description: 'Froiden Terms and Condition',
            },
        },
    },
    '/policy/privacy': {
        title: 'Privacy' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Privacy' + title,
            },
            name: {
                keywords: 'Privacy',
                description: 'Froiden Privacy Policy',
            },
        },
    },
    '/policy/refund-policy': {
        title: 'Refund policy' + title,
        meta: {
            property: {
                'og:url': document.URL,
                'og:title': 'Refund policy' + title,
            },
            name: {
                keywords: 'Refund policy',
                description: 'Froiden Refund policy',
            },
        },
    },
};

export default MetaData;
