import React from 'react';
import { Link } from 'react-router-dom';
import View from './View';

const NotFound = () => (
    <View>
        <section className="error-container">
            <div className="container-fluid fluid-reset">
                <div className="row error-image">
                    <div className="col-sm-12 text-xs-center text-sm-left">
                        <div className="logo">
                            <img src="/img/orange-logo.svg" alt="NOT FOUND" />
                        </div>
                        <span className="error-heading">404</span>
                        <span className="sub-heading">Oops! you&rsquo;re lost.</span>
                        <div className="sub-heading sub-heading2">We can not find the page you&rsquo;re looking for.</div>
                        <span className="reset-button">
                            <Link to="/" className="btn btn-orange btn-edit">
                                Back To Home
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    </View>
);

export default NotFound;
